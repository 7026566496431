<template>
	<S2SForm title="Active Orders" fluid>
		<v-btn icon slot="toolbar-content" @click="onShopChange()">
			<v-icon>refresh</v-icon>
		</v-btn>

		<v-layout>
			<v-flex xs12>
				<!-- We can move this into a component for seperation of concern purposes -->
				<v-item-group v-model="activeToggleId" mandatory>
					<v-container fluid px-0>
						<v-layout wrap>
							<v-flex sm6 md3 xs3 v-for="status in orderStatusOverview" :key="status.id" px-2 :py-2="$vuetify.breakpoint.smOnly">
								<v-item v-slot:default="{ active, toggle }" :value="status.id">
									<v-card :color="getStatusColor(status.id, active)" class="d-flex align-center" :dark="active" height="110" @click="toggle">
										<v-container grid-list-xs>
											<v-layout row wrap>
												<v-flex sm7 lg9 v-show="$vuetify.breakpoint.smAndUp">
													<v-flex xs12>
														<div class="display-1" v-if="!loading">{{ status.count }}</div>
														<div class="display-1" v-else>
															<v-progress-circular indeterminate></v-progress-circular>
														</div>
													</v-flex>
													<v-flex xs12>
														<div>{{ status.name }}</div>
													</v-flex>
												</v-flex>
												<v-flex xs12 lg3 sm5 class="text-center" align-self-center>
													<v-icon :size="$vuetify.breakpoint.smAndUp ? 60 : 40" :color="active ? 'white' : 'black'">{{
														getStatusIcon(status.id)
													}}</v-icon>
													<v-badge overlap v-if="$vuetify.breakpoint.xsOnly" class="pb-7">
														<span slot="badge">{{ status.count }}</span>
													</v-badge>
												</v-flex>
												<v-flex xs12 v-show="$vuetify.breakpoint.smAndUp">
													<div class="overline font-weight-medium">{{ status.description }}</div>
												</v-flex>
											</v-layout>
										</v-container>
									</v-card>
								</v-item>
							</v-flex>
						</v-layout>
					</v-container>
				</v-item-group>

				<v-row justify="end">
					<v-col cols="12" md="4">
						<v-text-field v-model="searchVal" label="Filter Customer" clearable hide-details @input="search()" append-icon="search"></v-text-field>
					</v-col>
					<v-col cols="12" md="2" class="text-right">
						<v-checkbox
							color="success"
							:loading.sync="loading"
							v-model="selectedPaymentStatus"
							label="PAID"
							value="PAID"
							@change="updateSelection"
							:rules="[selectedPaymentStatus => selectedPaymentStatus.length > 0 || 'At least one payment status is required']"
						></v-checkbox>
					</v-col>
					<v-col cols="12" md="2">
						<v-checkbox
							color="red"
							:loading.sync="loading"
							v-model="selectedPaymentStatus"
							label="UNPAID"
							value="UNPAID"
							@change="updateSelection"
							:rules="[selectedPaymentStatus => selectedPaymentStatus.length > 0 || 'At least one payment status is required']"
							hide-details
						></v-checkbox>
					</v-col>
				</v-row>

				<v-data-table
					:headers="headers"
					:items="orders"
					class="elevation-1"
					:loading="loading"
					calculate-widths
					:options.sync="options"
					:server-items-length="totalItems"
					:footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
				>
					<template v-slot:item.deliveryCost="{ item }">
						{{ item.deliveryCost == null || item.deliveryCost == 0 ? "Free" : item.deliveryCost }}
					</template>
					<template v-slot:item.actions="{ item }">
						<!-- Active order action buttons -->
						<template>
							<v-layout>
								<v-menu bottom origin="center center" transition="scale-transition" offset-y>
									<template v-slot:activator="{ on }">
										<v-btn color="accent" medium class="mr-2" v-on="on" @click.stop>
											<v-icon>more_horiz</v-icon>
										</v-btn>
									</template>
									<v-list>
										<v-list-item v-for="menu in getMenuByStatus()" :key="menu.title" @click="menu.action(item)">
											<pre>{{ menu }}</pre>
											<v-list-item-title>{{ menu.title }}</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
								<!-- Adds 180ms to DOM Patch -->
								<v-tooltip top>
									<template v-slot:activator="{ on }">
										<v-btn
											:color="item.currentPaymentStatus == 'PAID' ? 'success' : 'red'"
											v-on="on"
											medium
											@click.stop="
												onOrderMove(item);
												lastClickedOrderId = item.id;
											"
											:loading.sync="loading && lastClickedOrderId === item.id"
										>
											<v-icon>arrow_forward</v-icon>
										</v-btn>
									</template>
									<span>Move to {{ getNextOrderStatus(item.status.id, item.deliveryType).name }}</span>
								</v-tooltip>
								<v-tooltip top>
									<template v-slot:activator="{ on }">
										<v-btn color="grey" v-on="on" medium text @click="onOrder(item)" :loading.sync="loading" class="ml-1">
											<v-icon>mdi-eye</v-icon>
										</v-btn>
									</template>
									<span>View Order</span>
								</v-tooltip>
								<v-tooltip top>
									<template v-slot:activator="{ on }">
										<v-btn v-if="item.longitude" color="grey" v-on="on" medium text @click="onShowMap(item)" class="ml-1">
											<v-icon>map</v-icon>
										</v-btn>
									</template>
									<span>Show on Map</span>
								</v-tooltip>
								<v-tooltip top v-if="activeToggleId === 5 && item.parcelCount">
									<template v-slot:activator="{ on }">
										<v-btn color="grey" v-on="on" medium text @click="generateWaybill(item)" :loading.sync="loading" class="ml-1">
											<v-icon>mdi-qrcode</v-icon>
										</v-btn>
									</template>
									<span>Generate Waybill</span>
								</v-tooltip>
							</v-layout>
						</template>
					</template>
					<template v-slot:item.placedBy="{ item }">
						<div v-if="item.user">{{ item.user.profile.firstName }} {{ item.user.profile.surname }}</div>
						<div v-else>N/A</div>
					</template>
					<template v-slot:item.amount="{ item }">{{ formatCurrency(item.amount) }}</template>
					<template v-slot:item.dateCreated="{ item }">{{ formatDate(item.dateCreated) }}</template>
				</v-data-table>
				<SimpleDialog v-model="confirmPicup" title="Number of packages required" class="simple-dialog">
					<v-flex v-if="allowBoxSizes">
						<v-layout v-for="size in allowBoxSizes" :key="size.id">
							<v-text-field
								placeholder="# Packages"
								v-model="noOfPackages[size]"
								:label="boxSizes[size - 1].name"
								outlined
								hide-details
								class="mt-5"
							></v-text-field>
						</v-layout>
					</v-flex>
					<v-layout class="justify-end" slot="button-container">
						<v-btn text @click="confirmPicup = false" class="mr-2">Cancel</v-btn>
						<v-btn color="accent" :disabled="!Object.keys(noOfPackages).length" @click="setPackages">Save</v-btn>
					</v-layout>
				</SimpleDialog>
			</v-flex>
		</v-layout>
		<v-dialog v-model="mapDialog" scrollable fullscreen persistent max-width="500px" transition="dialog-transition">
			<v-card>
				<v-toolbar dark color="primary">
					<v-btn icon dark @click="mapDialog = false">
						<v-icon>close</v-icon>
					</v-btn>
					<v-toolbar-title>Order Location</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
				<leaflet-map v-if="mapDialog" v-bind:lat-lng.sync="addressLatLng" readonly></leaflet-map>
			</v-card>
		</v-dialog>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils/";
import SimpleDialog from "@/components/SimpleDialog.vue";
import LeafletMap from "@/components/LeafletMap.vue";

export default Vue.extend({
	name: "OrganisationActiveOrders",

	components: { SimpleDialog, LeafletMap },

	props: {
		toggle_id: {
			type: Number,
			default: 2
		},
		from: {
			type: String,
			default: ""
		}
	},

	computed: {
		loading: function() {
			return this.$store.state.organisations.activeOrders.loading;
		},
		orderStatusOverview: function() {
			return this.$store.state.organisations.activeOrders.orderStatusOverview;
		},
		orders: function() {
			return this.$store.state.organisations.activeOrders.orders;
		},
		totalItems: function() {
			return this.$store.state.organisations.activeOrders.totalItems;
		},
		boxSizes: function() {
			return this.$store.state.organisations.activeOrders.boxSizes;
		},
		activeOrderIds: function() {
			return this.$store.state.organisations.activeOrders.activeOrderIds;
		},

		ordersByStatus: function() {
			return this.$store.getters["organisations/activeOrders/ordersByStatus"];
		},
		activeOrdersStatus: function() {
			return this.$store.getters["organisations/activeOrders/activeOrdersStatus"];
		},
		getAllowedBoxSizes: function() {
			return this.$store.getters["organisations/activeOrders/getAllowedBoxSizes"];
		},
		deliverySettings: function() {
			return this.$store.getters["organisations/activeOrders/deliverySettings"];
		}
	},

	watch: {
		activeShopToken: {
			deep: true,
			handler() {
				this.$store.dispatch("organisations/activeOrders/fetchOrdersByStatus", {
					statusIds: [this.activeToggleId],
					paymentStatus: this.selectedPaymentStatus,
					orgsearch: this.searchVal,
					pagination: this.options
				});
			}
		},
		activeToggleId: {
			deep: true,
			handler() {
				// Set page in pagination to first result page
				this.options.page = 1;

				this.$store.dispatch("organisations/activeOrders/fetchOrdersByStatus", {
					statusIds: [this.activeToggleId],
					paymentStatus: this.selectedPaymentStatus,
					orgsearch: this.searchVal,
					pagination: this.options
				});
			}
		},
		options: {
			deep: true,
			handler() {
				this.$store.dispatch("organisations/activeOrders/fetchOrdersByStatus", {
					statusIds: [this.activeToggleId],
					paymentStatus: this.selectedPaymentStatus,
					orgsearch: this.searchVal,
					pagination: this.options
				});
			}
		},
		getAllowedBoxSizes: {
			deep: true,
			immediate: true,
			handler(newVal) {
				this.allowBoxSizes = newVal;
			}
		}
	},

	data: function() {
		return {
			lastClickedOrderId: NaN,
			canSearch: false,

			selectedPaymentStatus: ["PAID", "UNPAID"],
			currentPaymentStatusItems: ["PAID", "UNPAID"],
			lastSelectedcurrentPaymentStatus: [],

			activeToggleId: 2,
			searchVal: "",

			confirmPicup: false,
			allowBoxSizes: [],
			noOfPackages: {},
			picupOrder: {},
			parcelParams: {
				message: "",
				parcels: []
			},

			// PAGINATION
			options: {
				page: 1,
				itemsPerPage: 10,
				descending: false,
				sortBy: [],
				filters: {}
			},

			// Data grid columns
			headers: [
				{ text: "ID#", value: "id" },
				{ text: "Date Created", value: "dateCreated", type: "date", width: "150" },
				{ text: "Customer Code", value: "organisation.code", width: "130", sortable: false },
				{ text: "Customer", value: "organisation.name" },
				{ text: "Placed By", value: "placedBy" },
				{ text: "Fulfillment", value: "deliveryType" },
				{ text: "Delivery Fee", value: "deliveryCost", class: "currency", width: "100", sortable: false },
				{ text: "Total", value: "amount", class: "currency" },
				{ text: "Actions", value: "actions" }
			],

			organisationId: null,
			addressLatLng: {},
			mapDialog: false
		};
	},

	created() {
		this.activeToggleId = this.$props.from === "" ? this.$props.toggle_id : +this.$props.from;
		this.organisationId = this.$store.state.organisations.selectedOrganisation.id;
		this.$store.dispatch("organisations/activeOrders/fetchDeliverySettings");
		this.$store.dispatch("organisations/activeOrders/fetchBoxSizes");
		this.$store.dispatch("organisations/activeOrders/fetchAllowedBoxSizes");
		this.$store.dispatch("organisations/activeOrders/fetchOrderStatusOverview", {
			organisationId: this.organisationId,
			paymentStatus: this.selectedPaymentStatus
		});
	},

	mounted: function() {
		this.$store.dispatch("organisations/activeOrders/fetchOrderStatusses");
		this.$store.dispatch("organisations/activeOrders/fetchOrdersByStatus", { statusIds: [this.activeToggleId], paymentStatus: this.selectedPaymentStatus });
	},

	methods: {
		onShopChange() {
			this.$store.dispatch("organisations/activeOrders/fetchOrdersByStatus", {
				statusIds: [this.activeToggleId],
				paymentStatus: this.selectedPaymentStatus,
				orgsearch: this.searchVal,
				pagination: this.options
			});
		},
		onStatusChange() {
			// Set page in pagination to first result page
			this.options.page = 1;

			this.$store.dispatch("organisations/activeOrders/fetchOrdersByStatus", {
				statusIds: [this.activeToggleId],
				paymentStatus: this.selectedPaymentStatus,
				orgsearch: this.searchVal,
				pagination: this.options
			});
		},
		onPaginate() {
			this.$store.dispatch("organisations/activeOrders/fetchOrdersByStatus", {
				statusIds: [this.activeToggleId],
				paymentStatus: this.selectedPaymentStatus,
				orgsearch: this.searchVal,
				pagination: this.options
			});
		},
		onAllowedBoxSizesLoaded(newVal) {
			this.allowBoxSizes = newVal;
		},
		updateSelection() {
			if (this.selectedPaymentStatus.length > 0) {
				this.$store.dispatch("organisations/activeOrders/fetchOrdersByStatus", {
					statusIds: [this.activeToggleId],
					paymentStatus: this.selectedPaymentStatus,
					orgsearch: this.searchVal,
					pagination: this.options
				});
				this.$store.dispatch("organisations/activeOrders/fetchOrderStatusOverview", {
					organisationId: this.organisationId,
					paymentStatus: this.selectedPaymentStatus,
					orgsearch: this.searchVal
				});
			}
		},
		onOrder(order) {
			this.$router.push({ name: "organisation-order", params: { orderId: order.id.toString() } });
		},
		onOrderMove(order) {
			if (this.activeToggleId === 3 && order.deliveryType === "DELIVERY" && this.deliverySettings.requireBoxes) {
				// from accepted => ready
				this.confirmPicup = true;
				this.picupOrder = order;
			} else {
				this.$store.dispatch("organisations/activeOrders/changeOrderStatus", {
					order: order,
					statusIds: [this.activeToggleId],
					paymentStatus: this.selectedPaymentStatus,
					orgsearch: this.searchVal
				});
			}
		},
		setPackages() {
			// parcel data
			Object.keys(this.noOfPackages).forEach(sizeId => {
				let parcelData = {
					boxSizeId: sizeId,
					count: this.noOfPackages[sizeId]
				};

				this.parcelParams.parcels.push(parcelData);
			});

			this.$store.dispatch("organisations/activeOrders/changeOrderStatus", {
				order: this.picupOrder,
				statusIds: [this.activeToggleId],
				paymentStatus: this.selectedPaymentStatus,
				orgsearch: this.searchVal,
				params: this.parcelParams
			});

			this.noOfPackages = {};
			this.parcelParams.parcels = [];
			this.confirmPicup = false;
		},
		onOrderMoveBack(order) {
			this.$store.dispatch("organisations/activeOrders/moveOrderBackwards", {
				order: order,
				statusIds: [this.activeToggleId],
				paymentStatus: this.selectedPaymentStatus,
				orgsearch: this.searchVal
			});
		},
		onStatusDecline(order) {
			this.$store.dispatch("organisations/activeOrders/declineOrder", {
				order: order,
				statusIds: [this.activeToggleId],
				paymentStatus: this.selectedPaymentStatus,
				orgsearch: this.searchVal
			});
		},
		formatCurrency(item) {
			return Utils.formatText(item, Utils.TextType.CURRENCY);
		},
		formatDate(item) {
			return Utils.formatText(item, Utils.TextType.DATE_TIME);
		},
		getOrderStatusById(statusId) {
			for (let status in this.activeOrdersStatus) {
				if (this.activeOrdersStatus[status].id === statusId) return this.activeOrdersStatus[status];
			}

			// This will most likely not happen
			return this.activeOrdersStatus[0];
		},
		getNextOrderStatus(statusId, deliveryType) {
			switch (this.$props.toggle_id) {
				case 2:
					return this.getOrderStatusById(3);
				case 3:
					return this.getOrderStatusById(5);
				case 5:
					if (deliveryType === "DELIVERY") return this.getOrderStatusById(6);
					else return { name: "Collected" };
				default:
					return { name: "Completed" };
			}
		},
		getMenuByStatus() {
			let previousStatus = null;
			switch (this.activeToggleId) {
				case 3:
					previousStatus = this.getOrderStatusById(2);
					break;
				case 5:
					previousStatus = this.getOrderStatusById(3);
					break;
				case 6:
					previousStatus = this.getOrderStatusById(5);
					break;
				default:
					// No previous status available
					return [{ title: "Decline Order", action: this.onStatusDecline }];
			}
			return [
				{ title: "Decline Order", action: this.onStatusDecline },
				{ title: `Move to ${previousStatus.name}`, action: this.onOrderMoveBack }
			];
		},
		getStatusColor(statusId, active) {
			if (!active) return "white";
			switch (statusId) {
				case 2:
					return "light-blue";
				case 3:
					return "green";
				case 5:
					return "orange";
				case 6:
					return "#33404D";
				default:
					/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
					console.error("There is not color for status: " + status);
					return "pink";
			}
		},
		getStatusIcon(statusId) {
			switch (statusId) {
				case 2:
					return "mdi-timer-sand";
				case 3:
					return "check_box";
				case 5:
					return "thumb_up";
				case 6:
					return "mdi-truck-fast";
				default:
					/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
					console.error("There is not icon for status: " + status);
					return "close";
			}
		},
		generateWaybill(order) {
			this.$router.push({ name: "view-order-waybill", params: { orderId: order.id.toString(), from: "active-orders" } });
		},
		onShowMap(order) {
			this.mapDialog = true;
			this.addressLatLng = { lat: order.latitude, lng: order.longitude };
		},
		search() {
			const $this = this;
			setTimeout(function() {
				if ($this.searchVal == null) {
					$this.searchVal = "";
				}
				$this.$store.dispatch("organisations/activeOrders/fetchOrderStatusOverview", {
					organisationId: $this.organisationId,
					paymentStatus: $this.selectedPaymentStatus,
					orgsearch: $this.searchVal
				});

				// Set page in pagination to first result page
				$this.options.page = 1;

				$this.$store.dispatch("organisations/activeOrders/fetchOrdersByStatus", {
					statusIds: [$this.activeToggleId],
					paymentStatus: $this.selectedPaymentStatus,
					orgsearch: $this.searchVal,
					pagination: $this.options
				});
			}, 500);
		}
	}
});
</script>

<style lang="scss">
.grid-row {
	cursor: pointer;
}

.currency {
	min-width: 200px;
}

.v-btn {
	min-width: 0;
}
</style>
